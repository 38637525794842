var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c("el-row", { staticClass: "addTitle", attrs: { gutter: 24 } }, [
            _vm._v("基本信息"),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "协访人员", required: "" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !_vm.isAdd,
                            filterable: "",
                            clearable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入人员姓名模糊搜索",
                            "remote-method": function (query) {
                              _vm.remoteMethod(query, "person")
                            },
                            loading: _vm.loading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getChange($event, "person")
                            },
                          },
                          model: {
                            value: _vm.value.helpRealName,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "helpRealName", $$v)
                            },
                            expression: "value.helpRealName",
                          },
                        },
                        _vm._l(_vm.interviewOptions, function (item) {
                          return _c("el-option", {
                            key: item.positionCode,
                            attrs: {
                              label: item.fullName,
                              value: item.positionCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "协访日期", required: "" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          readonly: _vm.disabled,
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "选择协访日期",
                        },
                        on: { change: _vm.getDate },
                        model: {
                          value: _vm.value.helpDefenseDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "helpDefenseDate", $$v)
                          },
                          expression: "value.helpDefenseDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row", { staticClass: "addTitle", attrs: { gutter: 24 } }, [
            _vm._v("被协防人员拜访信息"),
          ]),
          _vm._l(_vm.sfaHelpDefenseList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "人员姓名", required: "" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled: _vm.disabled,
                                  filterable: "",
                                  clearable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入人员姓名模糊搜索",
                                  "remote-method": function (query) {
                                    _vm.remoteMethod(query, "interview")
                                  },
                                  loading: _vm.loading,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getChange(
                                      $event,
                                      "interview",
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: item.coverHelpRealName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "coverHelpRealName", $$v)
                                  },
                                  expression: "item.coverHelpRealName",
                                },
                              },
                              _vm._l(
                                item.interviewInfoOptions,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.fullName,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c("span", { staticClass: "pl5" }, [
                              _vm._v("(被协访人员)"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "人员职位" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: item.coverHelpPosName,
                                callback: function ($$v) {
                                  _vm.$set(item, "coverHelpPosName", $$v)
                                },
                                expression: "item.coverHelpPosName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "所属组织" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: item.coverHelpOrgName,
                                callback: function ($$v) {
                                  _vm.$set(item, "coverHelpOrgName", $$v)
                                },
                                expression: "item.coverHelpOrgName",
                              },
                            }),
                            !_vm.disabled
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-circle-plus-outline pl5",
                                  on: { click: _vm.addTable },
                                })
                              : _vm._e(),
                            index && !_vm.disabled
                              ? _c("i", {
                                  staticClass: "el-icon-remove-outline pl5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delTable(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.disabled
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "mb20", attrs: { md: 8 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCustomer(index, "customer")
                                  },
                                },
                              },
                              [_vm._v("添加客户")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCustomer(index, "terminal")
                                  },
                                },
                              },
                              [_vm._v("添加终端")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delChoose(index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "vxe-table",
                  {
                    ref: "xTable",
                    refInFor: true,
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      "edit-config": {
                        trigger: "click",
                        mode: "row",
                      },
                      data: item.sfaHelpDefenseDetailList,
                    },
                    on: {
                      "checkbox-change": function (ref) {
                        var records = ref.records

                        return _vm.dialog_checkboxChange(records, index)
                      },
                      "checkbox-all": function (ref) {
                        var records = ref.records

                        return _vm.dialog_checkAll(records, index)
                      },
                    },
                  },
                  [
                    _c("vxe-table-column", {
                      attrs: { type: "checkbox", width: "60" },
                    }),
                    _c("vxe-table-column", {
                      attrs: { field: "clientCode", title: "客户编码" },
                    }),
                    _c("vxe-table-column", {
                      attrs: { field: "clientType", title: "客户类型" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", { staticClass: "vxe-cell--label" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getClientName(row.clientType)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("vxe-table-column", {
                      attrs: { field: "clientName", title: "客户名称" },
                    }),
                    !_vm.disabled
                      ? _c("vxe-table-column", {
                          attrs: { title: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var rowIndex = ref.rowIndex
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cursor",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delTable(
                                              row,
                                              "table",
                                              rowIndex,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isCustomer
                  ? _c("AddDialog", {
                      attrs: { param: _vm.param },
                      on: {
                        closeAdd: function ($event) {
                          _vm.isCustomer = false
                        },
                        submitAdd: _vm.submitAdd,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }