<template>
  <div>
    <el-form>
      <el-row :gutter="24" class="addTitle">基本信息</el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="协访人员" required class="flex">
            <el-select
              v-model="value.helpRealName"
              :disabled="!isAdd"
              filterable
              clearable
              @change="getChange($event, 'person')"
              remote
              reserve-keyword
              placeholder="请输入人员姓名模糊搜索"
              :remote-method="(query)=>{remoteMethod(query,'person')}"
              :loading="loading">
              <el-option
                v-for="item in interviewOptions"
                :key="item.positionCode"
                :label="item.fullName"
                :value="item.positionCode">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="协访日期" required class="flex">
            <el-date-picker
              :readonly="disabled"
              value-format="yyyy-MM-dd"
              v-model="value.helpDefenseDate"
              type="date"
              @change="getDate"
              placeholder="选择协访日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="addTitle">被协防人员拜访信息</el-row>
      <div v-for="(item, index) in sfaHelpDefenseList" :key="index">
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="人员姓名" required class="flex">
              <el-select
                v-model="item.coverHelpRealName"
                :disabled="disabled"
                filterable
                clearable
                @change="getChange($event, 'interview', index)"
                remote
                reserve-keyword
                placeholder="请输入人员姓名模糊搜索"
                :remote-method="(query)=>{remoteMethod(query,'interview')}"
                :loading="loading">
                <el-option
                  v-for="item in item.interviewInfoOptions"
                  :key="item.id"
                  :label="item.fullName"
                  :value="item.id">
                </el-option>
              </el-select>
              <span class="pl5">(被协访人员)</span>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="人员职位" class="flex">
              <el-input readonly v-model="item.coverHelpPosName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="所属组织" class="flex">
              <el-input readonly v-model="item.coverHelpOrgName"></el-input>
              <i class="el-icon-circle-plus-outline pl5" @click="addTable" v-if="!disabled"></i>
              <i class="el-icon-remove-outline pl5" v-if="index && !disabled" @click="delTable(index)"></i>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="!disabled">
          <el-col :md="8" class="mb20">
            <el-button type="primary" size="small" @click="addCustomer(index, 'customer')">添加客户</el-button>
            <el-button type="primary" size="small" @click="addCustomer(index, 'terminal')">添加终端</el-button>
            <el-button size="small" @click="delChoose(index)">删除</el-button>
          </el-col>
        </el-row>
        <vxe-table
          :edit-config="{
            trigger:'click',
            mode: 'row',
          }"
          ref="xTable"
          @checkbox-change="({records}) => dialog_checkboxChange(records, index)"
          @checkbox-all="({records}) => dialog_checkAll(records, index)"
          :data="item.sfaHelpDefenseDetailList"
          style="margin-bottom: 20px"
        >
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column field="clientCode" title="客户编码"></vxe-table-column>
          <vxe-table-column field="clientType" title="客户类型">
            <template v-slot="{row}">
              <span class="vxe-cell--label"> {{ getClientName(row.clientType) }} </span>
            </template>
          </vxe-table-column>
          <vxe-table-column field="clientName" title="客户名称"></vxe-table-column>
          <vxe-table-column title="操作" v-if="!disabled">
             <template v-slot="{ row, rowIndex }">
               <span class="cursor" @click="delTable(row, 'table', rowIndex, index)">删除</span>
             </template>
          </vxe-table-column>
        </vxe-table>
        <AddDialog v-if="isCustomer" :param="param"  @closeAdd="isCustomer=false" @submitAdd="submitAdd"></AddDialog>
      </div>
    </el-form>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';
import AddDialog from './add_dialog.vue';

export default {
  props: {
    value: Object,
    disabled: Boolean,
    isAdd: Boolean,
  },
  components: {
    AddDialog,
  },
  data() {
    return {
      loading: false,
      isCustomer: false,
      isGetData: true,
      interviewOptions: [],
      sfaHelpDefenseList: [{
        coverHelpRealName: '', // 人员姓名
        coverHelpPosName: '', // 人员职位
        coverHelpOrgName: '', // 所属组织
        sfaHelpDefenseDetailList: [],
        interviewInfoOptions: [],
      }],
      pickerOption: {
        disabledDate: (time) => time.getTime() < new Date() - 8.64e7,
      },
      param: {},
      clientTypeDictData: [], // 客户类型数据字典
    };
  },
  created() {
    this.$emit('input', this.value);
    this.getPerson(null);
    this.getClientDict();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (!this.isAdd) {
          this.sfaHelpDefenseList = this.value.sfaHelpDefenseList || [];
        }
        if (!this.isAdd && !this.disabled && this.isGetData) {
          this.getInterview('init');
        }
      },
    },
  },
  methods: {
    // 获取客户类型数据字典
    async getClientDict() {
      const requestUrl = '/mdm/mdmdictdata/list';
      const { result } = await request.post(requestUrl, {
        dictTypeCode: 'sfacusType',
      });
      this.clientTypeDictData = result;
    },
    getClientName(clientType) {
      return this.clientTypeDictData.find((item) => item.dictCode === clientType).dictValue;
    },
    // 远程搜索
    remoteMethod(query, type) {
      this.loading = true;
      if (type === 'person') {
        this.getPerson(query);
      } else {
        this.getInterview(null, query);
      }
    },
    // 选中协访人员
    getChange(e, type, index) {
      if (type === 'person') {
        const checkValue = this.interviewOptions.find((val) => val.positionCode === e);
        if (checkValue) {
          const obj = {
            helpOrgCode: checkValue.orgCode,
            helpOrgName: checkValue.orgName,
            helpPosCode: checkValue.positionCode,
            helpPosName: checkValue.positionName,
            helpRealName: checkValue.fullName,
            helpUserName: checkValue.userName,
            sfaHelpDefenseList: [],
          };
          Object.assign(this.value, obj);
          this.sfaHelpDefenseList = [{
            coverHelpRealName: '', // 人员姓名
            coverHelpPosName: '', // 人员职位
            coverHelpOrgName: '', // 所属组织
            sfaHelpDefenseDetailList: [],
            interviewInfoOptions: [],
            checkList: [],
          }];
          if (this.isAdd) {
            this.getInterview('init');
          }
        }
      } else {
        const checkValue = this.sfaHelpDefenseList[index].interviewInfoOptions.find((val) => val.id === e);
        if (checkValue) {
          const obj = {
            coverHelpOrgCode: checkValue.orgCode,
            coverHelpOrgName: checkValue.orgName,
            coverHelpPosCode: checkValue.positionCode,
            coverHelpPosName: checkValue.positionName,
            coverHelpRealName: checkValue.fullName,
            coverHelpUserName: checkValue.userName,
            sfaHelpDefenseDetailList: [],
            checkList: [],
          };
          Object.assign(this.sfaHelpDefenseList[index], obj);
        }
      }
      this.value.sfaHelpDefenseList = this.sfaHelpDefenseList;
      this.$emit('input', this.value);
    },
    // 获取协访人员
    getPerson(fullName) {
      const data = {
        // #60926 选择协防人员时，应该选择全部人员，而不是根据当前登陆人
        // allUnderThisPositionCodeExcludeSelf: JSON.parse(localStorage.getItem('userInfo')).positionCode,
        fullName,
        includeEmptyUserPosition: false,
        enableStatus: '009',
      };
      request.post('/mdm/mdmPositionController/findPositionUserOrgPageList', data, { headers: { functionCode: 'select-position' } })
        .then((res) => {
        // 职位编码（查这个职位（不含自己）的全部下级职位）
          this.interviewOptions = res.result.data || [];
          const selfOption = JSON.parse(localStorage.getItem('userInfo'));
          if (fullName === null || fullName === selfOption.fullName) {
            this.interviewOptions.push(selfOption);
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    // 获取被协访人员姓名
    getInterview(type, query) {
      const data = {
        enableStatus: '009',
        includeEmptyUserPosition: false,
        // 职位编码（查这个职位（不含自己）的全部下级职位）
        allUnderThisPositionCodeExcludeSelf: this.value.helpPosCode,
        unionName: query,
      };
      if (!this.value.helpPosCode) {
        this.loading = false;
        this.$message.error('请选择协访人员');
      } else {
        request.post('/mdm/mdmPositionController/positionSelectList', data)
          .then((res) => {
            if (type === 'init') {
              this.sfaHelpDefenseList.map((v) => {
                const item = v;
                item.interviewInfoOptions = res.result;
                return item;
              });
            } else {
              const index = this.sfaHelpDefenseList.length - 1;
              this.sfaHelpDefenseList[index].interviewInfoOptions = res.result;
            }
            this.$forceUpdate();
          }).finally(() => {
            this.loading = false;
            this.isGetData = false;
          });
      }
    },
    // 获取协访日期
    getDate() {
      this.sfaHelpDefenseList = [{
        coverHelpRealName: '', // 人员姓名
        coverHelpPosName: '', // 人员职位
        coverHelpOrgName: '', // 所属组织
        sfaHelpDefenseDetailList: [],
        checkList: [],
        interviewInfoOptions: [],
      }];
      this.getInterview('init');
    },
    // 获取添加客户数据
    submitAdd(e, index) {
      this.isCustomer = false;
      this.sfaHelpDefenseList[index].sfaHelpDefenseDetailList = JSON.parse(JSON.stringify(this.getReset(this.sfaHelpDefenseList[index].sfaHelpDefenseDetailList, e, 'id')));
      if (!this.value.sfaHelpDefenseList) {
        this.value.sfaHelpDefenseList = [{
          coverHelpRealName: '', // 人员姓名
          coverHelpPosName: '', // 人员职位
          coverHelpOrgName: '', // 所属组织
          sfaHelpDefenseDetailList: [],
          checkList: [],
        }];
      }
      this.value.sfaHelpDefenseList[index].sfaHelpDefenseDetailList = JSON.parse(JSON.stringify(this.getReset(this.value.sfaHelpDefenseList[index].sfaHelpDefenseDetailList, e, 'id')));
      this.$emit('input', this.value);
    },
    // 添加客户
    addCustomer(index, type) {
      if (!this.value.helpDefenseDate) {
        this.$message.error('请选择协访日期');
      } else if (!this.sfaHelpDefenseList[index].coverHelpPosCode) {
        this.$message.error('请选择被协访人员信息');
      } else {
        this.isCustomer = true;
        this.param = {
          visitUserName: this.sfaHelpDefenseList[index].coverHelpUserName,
          visitPosCode: this.sfaHelpDefenseList[index].coverHelpPosCode,
          visitDate: this.value.helpDefenseDate,
          customerType: type,
          index,
        };
      }
    },
    // 删除表格某一行数据
    delTable(row, type, rowIndex, index) {
      if (type === 'table') {
        this.sfaHelpDefenseList[index].sfaHelpDefenseDetailList.splice(rowIndex, 1);
      } else {
        this.sfaHelpDefenseList.splice(row, 1);
      }
      this.value.sfaHelpDefenseList = this.sfaHelpDefenseList;
      this.$emit('input', this.value);
    },
    // 删除选中的数据
    delChoose(index) {
      this.sfaHelpDefenseList[index].checkList.map((v) => {
        const item = v;
        const checkIndex = this.sfaHelpDefenseList[index].sfaHelpDefenseDetailList.findIndex((val) => val.clientId === item.clientId);
        if (checkIndex > -1) {
          this.sfaHelpDefenseList[index].sfaHelpDefenseDetailList.splice(checkIndex, 1);
          this.value.sfaHelpDefenseList = this.sfaHelpDefenseList;
          this.$emit('input', this.value);
        }
        return item;
      });
    },
    // 添加
    addTable() {
      this.sfaHelpDefenseList.push({
        coverHelpRealName: '', // 人员姓名
        coverHelpPosName: '', // 人员职位
        coverHelpOrgName: '', // 所属组织
        sfaHelpDefenseDetailList: [],
        interviewInfoOptions: [],
        checkList: [],
      });
      this.getInterview('add');
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 单选
    dialog_checkboxChange(records, index) {
      this.sfaHelpDefenseList[index].checkList = records;
    },
    // 全选
    dialog_checkAll(records, index) {
      this.sfaHelpDefenseList[index].checkList = records;
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.el-row {
  margin: 0 !important;
}
/deep/ .el-form-item__label {
  width: 100px;
}
/deep/ .el-form-item__content {
  flex: 1;
  display: flex;
  align-items: center;
}
/deep/ .el-select{
  flex: 1;
}
.el-col {
  padding: 0 !important;
}
.addTitle{
  font-size: 16px;
  color: #333;
  padding-bottom: 20px;
}
.pl5{
  padding-left: 5px;
}
.el-icon-circle-plus-outline, .el-icon-remove-outline{
  font-size: 20px;
  cursor: pointer;
}
.mb20{
  margin-bottom: 20px;
}
.cursor{
  cursor: pointer;
}
</style>
