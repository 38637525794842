var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable",
              staticClass: "vxeTable",
              attrs: {
                border: "",
                "row-id": "code",
                loading: _vm.tableLoading,
                data: _vm.tableData,
              },
              on: {
                "checkbox-change": _vm.dialog_checkboxChange,
                "checkbox-all": _vm.dialog_checkAll,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              [
                _c("vxe-table-column", {
                  attrs: { field: "clientCode", title: "客户编号" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "clientName", title: "客户名称" },
                }),
              ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }